import { Button, Modal, Space } from 'antd/lib'
import React, { ReactElement, useEffect, useRef } from 'react'
import { Icon } from '../Icon/Icon'
import { IconNames } from '../Icon/icons'
import styles from './ConfirmationModal.module.css'

export interface ConfirmationModalProps {
  modalState: boolean
  confirmTitleText: string
  confirmQuestionText: string | ReactElement
  explanationText: string
  confirmGuideText?: string
  cancelText: string
  confirmText: string
  closeCallback: () => void
  confirmCallback: () => void
}

const ConfirmationModal = ({
  modalState,
  confirmTitleText,
  confirmQuestionText,
  explanationText,
  confirmGuideText,
  cancelText,
  confirmText,
  closeCallback,
  confirmCallback,
}: ConfirmationModalProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const onCancel = () => {
    closeCallback()
  }

  // Close popup and trigger callback
  const onConfirm = () => {
    confirmCallback()
    closeCallback()
  }

  const animationEndHandler = (ev) => {
    if ((ev.target as HTMLElement).id === 'confirm-upgrade') {
      onConfirm()
    }
  }

  useEffect(() => {
    if (!buttonRef.current) return
    // Remove listener when modal is closed
    if (!modalState) buttonRef.current.removeEventListener('animationend', animationEndHandler)
    buttonRef.current?.addEventListener('animationend', animationEndHandler)

    return () => {
      buttonRef.current?.removeEventListener('animationend', animationEndHandler)
    }
  }, [modalState])

  return (
    <Modal
      open={modalState}
      onCancel={onCancel}
      centered
      keyboard={true}
      width={438}
      footer={null}
      closeIcon={<Icon component={IconNames.CROSS} fill="#111213" size={24} />}
      closable={true}
      className={styles.modalMessage}
    >
      <h2 className="bold mb-2">{confirmTitleText}</h2>
      <p className="body-large semibold mb-2">{confirmQuestionText}</p>
      <p className="mb-2">{explanationText}</p>
      {confirmGuideText && <p>{confirmGuideText}</p>}
      <Space size={'middle'} className="mt-2">
        <Button type="ghost" aria-label="cancel" onClick={onCancel} className={styles.cancelButton}>
          {cancelText}
        </Button>
        <div className={styles.confirmContainer}>
          <Button
            ref={buttonRef}
            id="confirm-upgrade"
            type="ghost"
            aria-label="confirm"
            className={styles.confirmButton}
          >
            {confirmText}
          </Button>
        </div>
      </Space>
    </Modal>
  )
}

export { ConfirmationModal }
